import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "What are Javascript Callbacks For a Newbie",
  "author": "tzookb",
  "type": "post",
  "date": "2018-12-31T16:01:25.000Z",
  "url": "/2018/12/what-are-javascript-callbacks-for-a-newbie/",
  "categories": ["javascript"],
  "tags": ["callbacks", "javascript", "programing"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="wp-block-embed__wrapper">
  <iframe title="What are Javascript Callbacks For a Newbie" width="800" height="600" src="https://www.youtube.com/embed/xqFiO4jszfk?feature=oembed" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
So what are callbacks in Javascript? 
    <p>{`Simply put, those are `}<em parentName="p">{`functions`}</em>{` that passed over to other functions for the purpose of being called later on when. Functions in javascript are `}{`“`}{`first-class objects`}{`”`}{` that means they are just like Number, String, Arrays, Objects etc. You can create a variable and assign his value to a function like so:`}</p>
    <pre><code parentName="pre" {...{}}>{`  var iAmAFunction = function() {    
    //code in function
  }
  // If you want to run this function you simply
  // add parentheses to the variable name:
  iAmAFunction();
`}</code></pre>
    <p>{`and now lets say we have a function that accepts a function (callback) will do something and then call the callback when done.`}<br parentName="p"></br>{`
`}{`** I`}{`’`}{`ll start using the arrow shorthand for creating functions `}</p>
    <pre><code parentName="pre" {...{}}>{`const funcWithCallback = (other, callback) =&gt; { 
  console.log(other);
  callback();
}
`}</code></pre>
    <p>{`As for the code above what will be the output of this snippet: `}</p>
    <pre><code parentName="pre" {...{}}>{`funcWithCallback('passedParam', thisIsTheCallback);
//output will be:
// "passedParamthisIsTheCallback"
`}</code></pre>
    <p>{`You can always use anonymous function like so: `}</p>
    <pre><code parentName="pre" {...{}}>{`thisIsTheCallback(otherParam, function() {
  //this is the anonymous function
  //and it will be called inside "thisIsTheCallback"
})
`}</code></pre>
    <p>{`So our passed function (the callback) will be passed for these scenarios: `}</p>
    <ul>
      <li parentName="ul">{`in setTimeout or setInterval as you will want your callback to be called in X seconds or every X seconds.`}</li>
      <li parentName="ul">{`when you have an ajax call and you will want your callback to be called with the ajax response as soon as the ajax response has arrived.`}</li>
      <li parentName="ul">{`when loading a file on the server.`}</li>
      <li parentName="ul">{`when some event has happened in the UI or on your code`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      